import Store from '../../store/store';
import { makeDefaultForm } from '../../utils/regions';

let ACTIONS;

const validation = {
  Name: '',
  Description: '',
};

const defaultStore = {
  search: {
    value: '',
    label: '',
    suggest: [],
    init: false,
  },
  btnRequest: false,
  isNoEdit: true,
  isValidForm: false,
  formData: makeDefaultForm(),
  isRequest: false,
  regionLoad: true,
  regions: [],
  validation,
};

const reducer = (action, state) => {
  switch (action.type) {
    case ACTIONS.REGIONS.INPUT_SEARCH:
      return {
        ...state,
        search: {
          ...state.search,
          value: action.value,
          label: action.label,
          init: action.init,
        },
      };

    case ACTIONS.REGIONS.CHANGE_FIELD:
      return {
        ...action.updatedState,
      };

    case ACTIONS.REGIONS.GET_REGION:
      return {
        ...state,
        formData: action.formData,
        regionLoad: action.regionLoad,
      };

    case ACTIONS.REGIONS.SEARCH_REGIONS:
      return {
        ...state,
        regions: action.regions,
        isRequest: action.isRequest,
      };

    case ACTIONS.REGIONS.RESET:
      return defaultStore;

    case ACTIONS.REGIONS.DO_REQUEST:
      return {
        ...state,
        btnRequest: action.btnRequest,
      };

    case ACTIONS.REGIONS.ADD_REGION:
      return {
        ...state,
        addedRegionId: action.addedRegionId,
      };
  }

  return state;
};

export default function (core) {
  ACTIONS = core.getNamespace('ACTIONS');
  core.addStore('Regions', reducer, defaultStore);

  const createStore = () => new Store('Regions', reducer, defaultStore);

  return createStore();
}
