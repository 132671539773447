// eslint-disable-next-line
let ACTIONS;

const reducer = (action, state) => {
  switch (action.type) {
    default:
      break;
  }

  return state;
};

export default function (core) {
  ACTIONS = core.getNamespace('ACTIONS');
  core.addStore('Order', reducer, []);
}
