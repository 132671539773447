import Store from '../../store/store';

let ACTIONS;

const DEFAULT = {
  notifications: [],
  timers: [],
};

const reducer = (action, state) => {
  const newState = { ...state };

  switch (action.type) {
    case ACTIONS.NOTIFICATIONS.UPDATENOTIFICATIONS: {
      newState.notifications = action.payload;
      break;
    }

    case ACTIONS.NOTIFICATIONS.UPDATETIMERS: {
      newState.timers = action.payload;
      break;
    }
  }

  return newState;
};

export default function (core) {
  ACTIONS = core.getNamespace('ACTIONS');
  core.addStore('Notifications', reducer, DEFAULT);

  const createStore = () => new Store('Notifications', reducer, DEFAULT);

  return createStore();
}
