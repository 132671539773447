import initAppStore from './app';
import initAirlineCancel from './airlineCancel';
import initAirlineWarning from './airlineWarning';
import initUserStore from './user';
import initWorkplaceStore from './workspace';
import initFilterStore from './filter';
import initAccountStore from './account';
import initCompaniesStore from './companies';
import initEmployeesStore from './employees';
import initEmployeeStore from './employee';
import initCompanyStore from './company';
import initTripStore from './trip';
import initOrderStore from './order';
import initBusinessStore from './business';
import initSearchUsersStore from './searchUsers';
import initCartsAndNotesStore from './cartsAndNotes';
import initReviseActTestStore from './reviseacttest';
import initUnloading1cStore from './unloading1c';
import initSendingDiadocStore from './sendingDiadoc';
import initNotificationsStore from './notifications';
import initFeatureFlagsStore from './featureFlags';
import initAmoCRMStore from './amocrm';
import initCallsStore from './calls';
import initTrainTripStore from './tripTrain';
import initTrainCancel from './trainCancel';
import initHotelsStore from './hotels';
import initStaticsStore from './statics';
import initRegionsStore from './regions';
import initRailwayWarning from './railwayWarning';
import initHotelWarning from './hotelWarning';
import initTaxiWarning from './taxiWarning';
import initTransferWarning from './transferWarning';
import initVipHallWarning from './vipHallWarning';
import initDocumentsConstructor from './documentsConstructor';

export default function (core) {
  const stores = [];
  const storesFn = [
    initAppStore,
    initAirlineCancel,
    initAirlineWarning,
    initUserStore,
    initWorkplaceStore,
    initFilterStore,
    initAccountStore,
    initCompaniesStore,
    initEmployeesStore,
    initEmployeeStore,
    initCompanyStore,
    initTripStore,
    initOrderStore,
    initBusinessStore,
    initSearchUsersStore,
    initCartsAndNotesStore,
    initReviseActTestStore,
    initUnloading1cStore,
    initSendingDiadocStore,
    initNotificationsStore,
    initFeatureFlagsStore,
    initAmoCRMStore,
    initCallsStore,
    initTrainTripStore,
    initTrainCancel,
    initHotelsStore,
    initStaticsStore,
    initRegionsStore,
    initRailwayWarning,
    initHotelWarning,
    initTaxiWarning,
    initTransferWarning,
    initVipHallWarning,
    initDocumentsConstructor,
  ];

  storesFn.forEach((fn) => {
    stores.push(fn(core));
  });

  return stores;
}
