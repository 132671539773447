import { getMoment, startOfMonth } from '../../utils/formatDate';

let ACTIONS;

const DEFAULT = {
  startDate: startOfMonth(),
  endDate: getMoment(),
  maxDay: getMoment(),
  listCompany: [],
  period: [],
  source: [],
  cacheList: [],
  packagesFilter: [],
  filterCompanyId: [],
  currentPage: 1,
  itemsOnPage: 50,
  totalItems: 0,
  allCheckboxValue: false,
  loadingButton: false,
  status: {
    list: [],
    selected: [],
  },
};

const getObjectForSelect = item => (
  Array.isArray(item) ? { label: item[1], value: item[0] } : { label: item, value: item }
);

const getPagingView = (items, currentPage, itemsOnPage) => {
  const startInd = itemsOnPage * (currentPage - 1);
  return items.slice(startInd, startInd + itemsOnPage);
};

const reducer = (action, state) => {
  switch (action.type) {
    case ACTIONS.SENDINGDIADOC.LOAD_LIST_COMPANY: {
      const { status: { selected } } = state;
      const filter = action.packagesFilter.map(item => item.CompanyId);
      let filterList = action.packagesFilter.filter(item => filter.includes(item.CompanyId));

      if (selected.length) {
        filterList = filterList.filter(({ SendingStatus }) => selected.includes(SendingStatus));
      }

      const source = action.listCompany.map((item) => {
        const newItem = { ...item };

        newItem.Selected = false;

        return newItem;
      });

      return {
        ...state,
        cacheList: filterList,
        listCompany: getPagingView(source, DEFAULT.currentPage, state.itemsOnPage),
        source,
        totalItems: filterList.length,
        allCheckboxValue: DEFAULT.allCheckboxValue,
        filterCompanyId: filter,
        currentPage: DEFAULT.currentPage,
        status: {
          ...state.status,
          list: state.status.list,
          selected,
        },
      };
    }

    case ACTIONS.SENDINGDIADOC.CHANGE_DATE: {
      return {
        ...state,
        [action.field]: action.value,
      };
    }

    case ACTIONS.SENDINGDIADOC.GET_STATUS: {
      const statusList = Array.from(action.status, getObjectForSelect);
      const status = {
        list: statusList,
        selected: [],
      };

      return {
        ...state,
        status,
      };
    }

    case ACTIONS.SENDINGDIADOC.SELECT_PERIOD: {
      return {
        ...state,
        period: action.period,
      };
    }

    case ACTIONS.SENDINGDIADOC.SELECT_STATUS: {
      return {
        ...state,
        status: {
          ...state.status,
          list: state.status.list,
          selected: action.selected,
        },
      };
    }

    case ACTIONS.SENDINGDIADOC.UPDATE_ALL_CHECKBOX: {
      let source = state.source;

      source = state.source.map(item => ({
        ...item,
        Selected: action.value,
      }));
      const cacheList = state.cacheList.map(item => ({
        ...item,
        Selected: action.value,
      }));

      if (state.status.selected.length < 1) {
        return {
          ...state,
          source,
          listCompany: getPagingView(source, state.currentPage, state.itemsOnPage),
          cacheList,
          allCheckboxValue: action.value,
        };
      }

      return {
        ...state,
        source,
        cacheList,
        listCompany: getPagingView(cacheList, state.currentPage, state.itemsOnPage),
        allCheckboxValue: action.value,
      };
    }

    case ACTIONS.SENDINGDIADOC.CHANGE_CURRENT_PAGE: {
      return {
        ...state,
        listCompany: getPagingView(state.cacheList, action.currentPage, state.itemsOnPage),
        currentPage: action.currentPage,
      };
    }

    case ACTIONS.SENDINGDIADOC.UPDATE_CHECKBOX: {
      const source = state.source.map(item => ({
        ...item,
        Selected: item.Id === action.companyId ? action.value : item.Selected,
      }));
      const cacheList = state.cacheList.map(item => ({
        ...item,
        Selected: item.Id === action.companyId ? action.value : item.Selected,
      }));
      const allCheckboxValue = !action.value && state.allCheckboxValue ? false : state.allCheckboxValue;

      if (state.status.selected.length < 1) {
        return {
          ...state,
          allCheckboxValue,
          source,
          cacheList,
          listCompany: getPagingView(source, state.currentPage, state.itemsOnPage),
        };
      }

      return {
        ...state,
        allCheckboxValue,
        source,
        cacheList,
        listCompany: getPagingView(cacheList, state.currentPage, state.itemsOnPage),
      };
    }

    case ACTIONS.SENDINGDIADOC.RESET: {
      return {
        ...state,
        DEFAULT,
      };
    }
  }

  return state;
};

export default function (core) {
  ACTIONS = core.getNamespace('ACTIONS');
  core.addStore('SendingDiadoc', reducer, DEFAULT);
}

