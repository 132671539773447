let ACTIONS;

const DEFAULT = {
  listAct: [],
};

const reducer = (action, state) => {
  switch (action.type) {
    case ACTIONS.REVISEACTTEST.LOADLISTACT: {
      return {
        ...state,
        listAct: action.listAct,
      };
    }
  }

  return state;
};

export default function (core) {
  ACTIONS = core.getNamespace('ACTIONS');
  core.addStore('reviseActTest', reducer, DEFAULT);
}
