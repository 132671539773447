const STATUS_REQUEST = {
  ERROR: 'Ошибка запроса. Нет ответа от сервера.',
};

const DEFAULT = {
  loading: false,
  provider: 'None',
  orderId: '',
  ticketId: '',
  success: false,
  resultMessage: '',
  refundSum: 0,
  error: '',
};

let ACTIONS;

const reducer = (action, state) => {
  switch (action.type) {
    case ACTIONS.TRAIN_CANCEL.UPDATE_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }

    case ACTIONS.TRAIN_CANCEL.CANCEL_TRAIN_ITEM: {
      const { success, error, refundSum } = action.payload;

      return {
        ...state,
        success,
        resultMessage: error,
        refundSum,
      };
    }

    case ACTIONS.TRAIN_CANCEL.RESET: {
      return {
        ...DEFAULT,
      };
    }

    case ACTIONS.TRAIN_CANCEL.REQUEST_ERROR: {
      return {
        ...state,
        success: false,
        error: STATUS_REQUEST.ERROR,
        provider: DEFAULT.provider,
        orderId: DEFAULT.orderId,
        ticketId: DEFAULT.ticketId,
      };
    }
  }

  return state;
};

export default function (core) {
  ACTIONS = core.getNamespace('ACTIONS');
  core.addStore('TrainCancel', reducer, DEFAULT);
}

