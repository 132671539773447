import Store from '../../store/store';

let ACTIONS;

const DEFAULT = {
  init: false,
  label: '',
  value: '',
  list: [],
};

const reducer = (action, state) => {
  let newState = { ...state };

  switch (action.type) {
    case ACTIONS.SEARCHUSERS.SETSEARCHUSERS: {
      newState.init = true;
      newState.label = action.label;
      break;
    }

    case ACTIONS.SEARCHUSERS.UNSETSEARCHUSERS: {
      newState = {
        init: false,
        label: '',
        value: '',
        list: [],
      };
      break;
    }

    case ACTIONS.SEARCHUSERS.CHANGESEARCHUSERSVALUE: {
      newState.value = action.value;
      break;
    }

    case ACTIONS.SEARCHUSERS.UPDATEUSERSLIST: {
      newState.list = action.res;
      break;
    }
  }

  return newState;
};

export default function (core) {
  ACTIONS = core.getNamespace('ACTIONS');
  core.addStore('SearchUsers', reducer, DEFAULT);

  const createStore = () => new Store('SearchUsers', reducer, DEFAULT);

  return createStore();
}
